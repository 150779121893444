import React from "react";

const TwoFa = () => {
  return (
    <div className="flex h-[500px] my-10  relative overflow-hidden">
      <img
        src="../../../images/chartpage4.webp"
        alt=""
        className="absolute -left-32 z-10 -bottom-10 h-[400px] lg:block hidden"
      />
      <div className="flex flex-col lg:flex-row relative items-center justify-center lg:w-8/12 w-full ml-auto bg-gray-100 lg:px-0 px-3">
        <img
          src="../../../images/lock.webp"
          alt=""
          className="absolute -left-60 bottom-9 z-30 h-96 lg:block hidden"
        />
        <img
          src="../../../images/blueslant.webp"
          alt=""
          className="absolute -left-32 z-20 top-0 bottom-0 h-[500px] lg:block hidden"
        />
        <div className="lg:w-8/12 w-full">
          <div className="grid lg:grid-cols-2 grid-cols-1">
            <div className="">
              <div className="russo text-2xl leading-3">
                Two-Factor <br />
                <span className="text-colorPrimaryBlue russo text-4xl">
                  authentication
                </span>{" "}
              </div>
              <div className="text-gray-500 text-sm">Security ----</div>
              <div className="text-black text-sm mt-5 font-semibold">
                This is a user identification method on a service that uses two
                dissent types of data authentication.{" "}
              </div>
              <div className="text-gray-500 text-sm mt-5">
                Introducing an additional security level ensures more efficient
                account protection from unauthorized access.{" "}
              </div>
              <div className="text-gray-500 text-sm mt-5">
                Flexible security settings are available for each Coinoma
                Limited user in their personal account: tracking IP address
                changes; tracking of browser changes; authorization, withdrawal
                of funds, and change of personal data using two-factor data
                verification.{" "}
              </div>
            </div>
            <div className="lg:pl-20 pl-0 text-sm">
              <div className="text-gray-500 text-sm mt-5">
                Please note! Two-factor authentication is not activated by
                default in account settings. Please enable 2FA in your account
                settings to ensure maximum personal data security.{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute right-0 lg:-bottom-16 bottom-0  z-0">
        <div className=" z-0  overflow-hidden">
          <div className="text-[10vw] text-slate-200 ">OCEAN</div>
        </div>
      </div>
    </div>
  );
};

export default TwoFa;
