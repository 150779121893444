import React from 'react'
import { Link } from 'react-router-dom';

const CallToAction = () => {
    return (
        <>
            <div className="outter-shadow mb-6 container mx-auto">
                <div className="text-center w-full mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 z-20">
                    <h2 className=" font-extrabold text-[#292929] dark:text-white ">
                        <span className="xl:text-6xl md:text-4xl text-xl block">
                            Ready to Join the Trade Genius
                        </span>
                        <span className="md:text-4xl text-xl block bg-gradient-to-r from-[#5fc3c3] to-[#31515F] text-transparent bg-clip-text mb-4">
                            Community?
                        </span>
                    </h2>
                    <p className="md:text-2xl text-md mt-3 mb-3 mx-auto text-[#292929] font-medium">
                        Take your first step with Fingenius today.
                    </p>
                    <div className="lg:mt-0 lg:flex-shrink-0">
                        <div className="mt-12 inline-flex rounded-md shadow">
                            <div>
                                <Link
                                    to="https://beta.fingenius.ai/"
                                    className="px-6 inline-block py-3 w-full sm:w-fit rounded-full mr-4 btn-gradient text-white shadow-neumorphic font-semibold text-xl uppercase"
                                >
                                    Start Trading Smarter
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default CallToAction;