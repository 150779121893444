import axios from 'axios';
import React, { useEffect, useState } from 'react';
import image1 from '../components/img/BNBUSDT.png'
import image2 from '../components/img/ETHUSDT.png';
import image3 from '../components/img/OCEANUSDT.png';
import image4 from '../components/img/DOGEUSDT.png';
import image5 from '../components/img/SOLUSDT.png';
import image6 from '../components/img/GALAUSDT.png';
import image7 from '../components/img/ADAUSDT.png';
import image8 from '../components/img/MANAUSDT.png';

const LiveCards = () => {

    const [data, setData] = useState([]);

    const generateImageUrl = (symbol) => {
        switch (symbol) {
            case "BNBUSDT":
                return image1;
            case "ETHUSDT":
                return image2;
            case "OCEANUSDT":
                return image3;
            case "DOGEUSDT":
                return image4;
            case "SOLUSDT":
                return image5;
            case "GALAUSDT":
                return image6;
            case "ADAUSDT":
                return image7;
            case "MANAUSDT":
                return image8;

            default:
                return "";
        }
    };

    const ViewBot = async () => {
        console.log("running view bot");
        const formData = new FormData();
        formData.append("project", "FinGenius");
        const response = await axios({
            method: "POST",
            headers: { "Content-Type": "application/json" },
            url: 'https://algu.stashack.cloud/bot/view_bot',
            data: formData,
        })
            .then(async function (response) {
                console.log(response.data.Data)
                const res = response.data.Data;
                const botDataWithImages = await Promise.all(
                    res.map(async (botItem) => {
                        return {
                            ...botItem,
                            images: generateImageUrl(botItem?.Symbol),
                            data: data,
                            avg_profite: botItem?.Avg_Profit_Percent['1W']
                        };
                    })

                );
                setData(botDataWithImages);
            })

            .catch((err) => {
                if (err.response) {
                }
            });
    };

    useEffect(() => {
        ViewBot();
    }, []);
    return (
        <>
            <section className="py-3 sm:py-20 relative mt-10" id='BOTS'>

                {/* <div className="absolute left-0 right-0 -top-24 sm:-top-64 z-0 md:block hidden overflow-hidden py-5">
                    <div className="text-[10vw] sm:text-[15vw] mt-28 text-center">
                        <span className='outlined'>BOTS</span>
                    </div>
                </div> */}
                <div className=" absolute left-0 right-0 -top-24 sm:-top-56 -z-20 md:block hidden overflow-hidden py-5">
                    <div className="text-[8vw] sm:text-[11vw] mt-36 text-center opacity-30">
                        <span className='outlined'>BOTS</span>
                    </div>
                </div>
                <div className="container mx-auto py-14 px-5">
                    <div className="grid grid-cols-1 sm:grid-cols-4 gap-6">
                        {/* Mapping over the cardData array */}
                        {data.map((card, index) => (
                            <div
                                key={index}
                                className="outter-shadow rounded-lg p-4 text-center relative overflow-hidden z-10 bg-[#ebebeb]"
                            >
                                <div className="flex">
                                    {/* Title */}
                                    <div className='px-5 py-1 flex items-center text-md uppercase font-bold leading-snug text-[#292929] hover:opacity-75 cursor-pointer inner-shadow w-6/12'>
                                        <p className='text-center mx-auto'>{card.Name}</p>
                                    </div>
                                    <div className='px-5 py-1 flex items-center text-md uppercase font-bold leading-snug text-[#292929] hover:opacity-75 cursor-pointer inner-shadow w-6/12'>
                                        <p className='text-center mx-auto'>{card.Trade_Type}</p>
                                    </div>
                                    {/* Image */}
                                </div>

                                <div className="flex items-center justify-center">
                                    <div className='px-5 py-1 flex items-center text-md uppercase font-bold leading-snug text-[#292929] hover:opacity-75 cursor-pointer w-6/12'>
                                        <img
                                            src={card.images}
                                            alt="fingenius"
                                            className="mx-auto z-10 relative"
                                            style={{ maxHeight: '100%', maxWidth: '100%' }}
                                        />
                                    </div>
                                    <div className='px-5 py-1 flex items-center justify-center text-md uppercase font-bold leading-snug text-[#292929] hover:opacity-75 cursor-pointer w-6/12 h-8 mt-10 border border-[#31515F] m-5 rounded-2xl' >
                                        <p className='text-center mx-auto'>{card.Symbol}</p>
                                    </div>
                                </div>

                                {/* Profit Details */}
                                <div className="z-10 relative">
                                    <div className="text-2xl text-[#4D4D4D] live-price font-bold mt-2 mb-1 ">
                                        Average Profits%
                                        <div className="livepriceper text-5xl font-semibold text-[#248000] text-center">
                                            <p>{card.Avg_Profit_Percent['1W']} %</p>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='py-1 w-8/12 mx-auto flex items-center text-lg uppercase font-bold leading-snug text-white hover:opacity-75 cursor-pointer mt-3 rounded-2xl btn-gradient'>
                                    <p className='text-center mx-auto'>Start Now</p>
                                </div> */}
                                <a href="https://beta.fingenius.ai/" className='text-center'>
                                    <div className="py-1 w-8/12 items-center text-lg uppercase font-bold leading-snug text-white hover:opacity-75 cursor-pointer mt-3 rounded-2xl btn-gradient mx-auto">
                                        <p className='tex-center'>Start Now</p>
                                    </div>
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    )
}

export default LiveCards