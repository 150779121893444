import React from "react";
import AllInOnePlatform from "../components/AllInOnePlatform";
import HomePageHeroSection from "../components/HomePageHeroSection";
import TopCryptoExchange from "../components/TopCryptoExchange";
import TradeLikeAPro from "../components/TradeLikeAPro";
import Faqs from "../components/Faqs";
import CallToAction from "../components/CallToAction";
import Coins from "../components/Coins";
import Steps from "../components/Steps";
import USP from "../components/USP";
import Accordian from "../components/Accordian";
import TradingGame from "../components/TradingGame";
import LiveCards from "../components/LiveCards";

const Home = () => {
  return (
    <>
      <div className="">
        <HomePageHeroSection />
      </div>
      <div className="mx-auto">
        <Coins />
        <TopCryptoExchange />
        <LiveCards />
        <AllInOnePlatform />
        {/* <TradeLikeAPro /> */}
        <TradingGame />
        <USP />
        <Steps />
        <Faqs />
        <CallToAction />
      </div>
    </>
  );
};

export default Home;
