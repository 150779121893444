import React from "react";
import DetailedInfo from "../components/DetailedInfo";
import CryptoExchange from "../components/CryptoExchange";
import SkillsOfTraders from "../components/SkillsOfTraders";
import Driving from "../components/Driving";
import Subheadersection from "../components/Subheadersection";

const About = () => {
  return (
    <div>
      <Subheadersection />
      <div className="lg:mx-0 mx-3">
        <DetailedInfo />
        <CryptoExchange />
        <SkillsOfTraders />
        <Driving />
      </div>
    </div>
  );
};

export default About;
