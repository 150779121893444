import React from 'react';
import Binance from '../components/img/bnb.png';
import BTC from '../components/img/BTC.png';
import Cardano from '../components/img/cardano.png';
import Ethereum from '../components/img/ethereum.png';
import Doge from '../components/img/doge.png';
import Ripple from '../components/img/ripple.png';
import Solana from '../components/img/solana.png';
import OceanCoin from '../components/img/Ocean-coin.png';
import Gala from '../components/img/Gala.png';
import Mana from '../components/img/Mana.png';

const Coins = () => {
    return (
        <>
            <h3 className="font-bold text-4xl text-center text-[#4D4D4D] mb-10 pt-20">
                Supporting Pairs
            </h3>
            <div className="grid grid-cols-2 sm:grid-cols-6 md:grid-cols-8 lg:grid-cols-10 xl:grid-cols-10 pb-0 sm:pb-20 container mx-auto"
                style={{ gap: '0' }}>

                <img src={Binance} alt="Binance" className="neumorphic-image mx-auto transition-transform hover:scale-110 p-0" />
                <img src={BTC} alt="BTC" className="neumorphic-image mx-auto transition-transform hover:scale-110 p-2" />
                <img src={Cardano} alt="Cardano" className="neumorphic-image mx-auto transition-transform hover:scale-110 p-0" />
                <img src={Ethereum} alt="Ethereum" className="neumorphic-image mx-auto transition-transform hover:scale-110 p-0" />
                <img src={Doge} alt="Doge" className="neumorphic-image mx-auto transition-transform hover:scale-110 p-0" />
                <img src={Ripple} alt="Ripple" className="neumorphic-image mx-auto transition-transform hover:scale-110 p-0" />
                <img src={Solana} alt="Solana" className="neumorphic-image mx-auto transition-transform hover:scale-110 p-0" />
                <img src={OceanCoin} alt="OceanCoin" className="neumorphic-image mx-auto transition-transform hover:scale-110 p-2" />
                <img src={Gala} alt="Gala" className="neumorphic-image mx-auto transition-transform hover:scale-110 p-2" />
                <img src={Mana} alt="Mana" className="neumorphic-image mx-auto transition-transform hover:scale-110 p-2" />
            </div>

        </>
    )
}

export default Coins