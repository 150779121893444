import React from "react";
import graph from '../components/img/gradient.png';

const sliderImageUrl = [
    {
        url: "../../../images/Spot-and-futures-trading-bot.png",
        title: "Spot and futures trading bot",
        desc: "Build flexible bots for spot and futures markets, enhancing your trading effectiveness.",
        num: 1
    },
    {
        url: "../../../images/Long-and-short-trading-bot.png",
        title: "Long and short trading bot",
        desc: "Profit in both rising and falling markets with long and short trading bots.",
        num: 2
    },
    {
        url: "../../../images/Risk-management.png",
        title: "Risk management",
        desc: "Protect your investments with our advanced Risk Matrix feature in challenging markets.",
        num: 3
    },
    {
        url: "../../../images/Demo-bot-trading.png",
        title: "Paper bot trading",
        desc: "Explore paper trading to understand the crypto bot and test strategies risk-free.",
        num: 4
    },
];
const getRandomPosition = () => {
    const randomX = Math.floor(Math.random() * 100) + 1; // Random X position
    const randomY = Math.floor(Math.random() * 100) + 1; // Random Y position
    return { x: randomX, y: randomY };
};

const TradingGame = () => {
    return (
        <>
            <section className="sm:py-5 relative" >
                <div className="container mx-auto">
                    <div className="text-2xl sm:text-4xl font-bold leading-9 sm:leading-10 text-[#4D4D4D] text-center py-6 sm:py-10">
                        How Automated Trading Bots Can Skyrocket Your Returns.
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
                        {sliderImageUrl.map((data, index) => {
                            const randomPosition = getRandomPosition();
                            const style = {
                                left: `${randomPosition.x}%`,
                                top: `${randomPosition.y}%`,
                            };

                            return (
                                <div className="inner-shadow rounded-lg p-4 m-3 sm:m-0 text-center relative overflow-hidden z-10 bg-[#ebebeb] h-[320px] sm:h-[280px] md:h-[300px] lg:h-[280px] xl:h-[290px]" key={index}>
                                    <div className="z-10 relative h-full flex flex-col justify-between">
                                        <div>
                                            <div className="text-xl font-bold mt-2 mb-1 inner-shadow flex items-center justify-center">
                                                <div className="bg-[#31515F] w-10 h-10 rounded-l-[16px] text-white flex items-center justify-center">
                                                    {data.num}
                                                </div>
                                                <div className="flex-grow text-center mt-1 sm:mt-2 text-[#4D4D4D]">
                                                    {data.title}
                                                </div>
                                            </div>
                                            <div className="font-medium outter-shadow mt-10 lg:mt-7 text-lg md:h-full lg:h-[110px] xl:h-[90px]">{data.desc}</div>
                                        </div>
                                        <div className="mt-4">
                                            <img src={graph} alt="" className="w-full h-auto" />
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>
        </>
    );
};

export default TradingGame;
