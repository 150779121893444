import React from "react";
import Security from "../components/Security";
import TwoFa from "../components/TwoFa";
import Subheadersection from "../components/Subheadersection";

const TwoFactorAuth = () => {
  return (
    <>
      <Subheadersection />
      <div className="mx-3 lg:mx-0">
        <Security />
        <TwoFa />
      </div>
    </>
  );
};

export default TwoFactorAuth;
