import React from "react";
const Start = () => {
  return (
    <>
      <section className="lg:w-8/12 w-full mx-auto">
        <div className="block">
          <div className="flex md:flex-row flex-col justify-between items-center">
            <div className="md:w-6/12 w-full">
              <div className="text-black text-5xl russo">
                How to Start
                <br />
                <span className="text-colorPrimaryGold russo">
                  Where to Start from
                </span>
              </div>
              <p className="text-md mb-3 text-gray-400 leading-5 mt-4">
                If you doubt your own skills, then start trading with Ocean
                Surf. The Ocean Surf company will assist you in making a profit
                from cryptocurrency trading without any risks since our team of
                experienced traders and analysts will automate this process and
                provide you with guaranteed profits.
              </p>
            </div>
            <div className="md:w-6/12 w-full z-20 relative">
              <div className="">
                <div className="gap-4 flex justify-start items-center">
                  <div className="rounded-full border-dashed border-2 border-orange-300 p-1">
                    <div className="text-black text-4xl font-bold bg-orange-300 rounded-full px-4 py-1">
                      1
                    </div>
                  </div>
                  <div className="text-black text-xl my-2 font-bold flex items-left">
                    PERSONAL ACCOUNT REGISTRATION.
                  </div>
                </div>
                <p className="text-md mb-3 text-gray-400 leading-5 mt-4">
                  Follow a simple signup procedure and complete all the
                  necessary information on the Create Account page.
                </p>
              </div>
              <div className="bg-sky-100 rounded-lg p-5 pl-8 relative">
                <p className="russo text-red-500">Please, Attend!</p>
                <p className="text-sm">
                  Online Registration does not require any specific personal
                  details
                </p>
                <img
                  src="../../../images/warning.png"
                  className="absolute -rotate-12 right-0 top-1/2"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Start;
