import React from "react";

const data = [
  {
    id: 2,
    desc:
    "To enter the deposit amount, click the 'New Deposit' button in your personal account. There is no limit for investment .",
    title: "MAKING A DEPOSIT ACCOUNT",
    numImage: "../../../images/makingdeposit.webp",
    ImageLeft: true,
  },
  {
    id: 3,
    desc:
    "Once you create and activate a deposit, you become an active platform member and generate profits. It is an automated process that runs every 24 hours after the deposit is activated. ",
    title2:
      "dolor lorem ipsum dolor lorem ipsum dolor dolor lorem ipsum dolor lorem ipsum dolor dolor lorem ipsum dolor lorem ipsum dolor  2",
    title: "PROFIT ACCRUAL",
    numImage: "../../../images/profit_actual.webp",
    ImageLeft: false,
  },
  {
    id: 4,
    desc:
      "To withdraw cash from your personal account, you need to browse to the Withdrawal of funds page, enter the withdrawal amount and click on the 'Request' button. There is just one slight restriction: the minimum withdrawal amount depends on the currency you are investing in.",
    title: "WITHDRAWING FUNDS",
    numImage: "../../../images/withdrawalfunding.webp",
    ImageLeft: true,
  },
  // Add more data objects as needed
];

const Start234 = () => {
  return (
    <div className="lg:w-8/12 w-full mx-auto">
      <div className="flex-col">
        {data.map((item, index) => (
          <div key={item.id} className="">
            {item.ImageLeft ? (
              <div className="shadow-lg my-11  bg-white md:my-0 md:shadow-none rounded-lg p-0 ">
                <div className="flex md:flex-row flex-col gap-2 justify-between items-start md:p-10 p-2">
                  <img
                    src={item.numImage}
                    alt={item.desc}
                    className="object-contain animate-slow-floating w-52 md:mr-auto mx-auto"
                  />
                  <div className="md:w-8/12 w-full">
                    <div className="font-bold text-xl"></div>
                    <div className="">
                      <div className="gap-4 flex justify-start items-center md:w-10/12 w-full">
                        <div className="rounded-full border-dashed border-2 border-orange-300 p-1">
                          <div className="text-black text-4xl font-bold bg-orange-300 rounded-full px-4 py-1">
                            {item.id}
                          </div>
                        </div>
                        <div className="text-black text-xl my-2 font-bold flex items-left">
                          {item.title}
                        </div>
                      </div>
                      <p className="text-md mb-3 text-gray-400 leading-5 mt-4">
                        {item.desc}
                      </p>
                    </div>
                    {index === 0 && (
                      <div className="flex md:flex-row flex-col flex-shrink-0 gap-3 justify-between items-center ">
                        <div className="z-20 relative">
                          <button className="bg-colorPrimaryBlue rounded-xl shadow-2xl transition-all duration-500	 hover:shadow-none text-white px-6  py-1">
                            MAKE A DEPOSIT
                          </button>
                        </div>
                        <p className="text-md mb-3 w-8/12 text-black font-semibold leading-5 mt-4">
                          The company's investment offers daily profit till validation.
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="shadow-lg my-11  bg-white md:my-0 md:shadow-none rounded-lg p-0">
                <div className="flex md:flex-row flex-col-reverse justify-center items-center">
                  <div className="md:w-8/12 w-full">
                    <div className="font-bold text-xl"></div>
                    <div className="">
                      <div className="gap-4 flex justify-start items-center md:w-6/12 w-full">
                        <div className="rounded-full border-dashed border-2 border-orange-300 p-1">
                          <div className="text-black text-4xl font-bold bg-orange-300 rounded-full px-4 py-1">
                            {item.id}
                          </div>
                        </div>
                        <div className="text-black text-xl my-2 font-bold flex items-left">
                          {item.title}
                        </div>
                      </div>
                      <p className="text-md mb-3 text-gray-400 leading-5 mt-4">
                        {item.desc}
                      </p>
                    </div>
                  </div>
                  <img
                    src={item.numImage}
                    alt={item.desc}
                    className="object-contain animate-slow-floating w-52 md:mr-auto mx-auto"
                  />
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Start234;
