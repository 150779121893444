import React from "react";
const Security = () => {
  return (
    <>
      <section className=" bg-fixed pb-24 pt-16 relative ">
        <div className="block z-10 lg:w-9/12 w-full mx-auto">
          <div className="flex flex-col-reverse lg:flex-row justify-between items-start">
            <div className="lg:w-4/12">
              <p className="text-sm">
                The main advantages of our platform are :
              </p>
              <p className="text-md font-bold mb-3 text-black leading-5 mt-4">
                Protection from DDos attacks
              </p>
              <p className="text-md mb-3 text-gray-400 leading-5 mt-4">
                It is a full-scale protection using an up-to-date equipment
                complex and resisting all known DDoS attacks by continuously
                monitoring and supporting current filtration rules. Such an
                approach ensures the continuous operation of the platform and
                helps avoid unpleasant consequences.
              </p>
              <p className="text-md font-bold mb-3 text-black leading-5 mt-4">
                Dedicated server
              </p>
              <p className="text-md mb-3 text-gray-400 leading-5 mt-4">
                This is a separate physical data storage, all the resources at
                the lessee's disposal. The "dedicated server" service means
                renting one physical server by one client. You choose the
                hardware, calculating possible loads in the future, and the
                hoster places the server in a rack cabinet in the data center.
              </p>
            </div>
            <div className="lg:w-8/12 w-full lg:ml-24 ml-0">
              <div className="text-black text-2xl russo w-3/5">
                COMPANY SECURITY <br />
                <span className="text-colorPrimaryBlue text-4xl russo">
                  GUARANTEES
                </span>
              </div>
              <p className="text-md font-bold mb-3 text-black leading-5 mt-4">
                Ocean Surf cares about the security of each of our clients{" "}
                <br /> with no exceptions.
              </p>
              <div className="flex flex-col lg:flex-row gap-7">
                <p className="text-md mb-3 text-gray-400 leading-5 mt-4">
                  To ensure a high level of protection of personal data and
                  personal funds of our clients, we have implemented all
                  possible security systems and also taken care of the
                  flexibility of customized data in your personal account of the
                  platform,
                </p>
                <p className="text-md mb-3 text-gray-400 leading-5 mt-4">
                  We use only a licensed program product and ensure absolute
                  user data protection through SSL encryption.
                </p>
              </div>
              <p className="text-md font-bold mb-3 text-black leading-5 mt-4">
                EV-SSL (Extended Validation)
              </p>
              <p className="text-md mb-3 text-gray-400 leading-5 mt-4">
                This is the most efficient and prestigious solution widely used
                in online business. This certificate lets you achieve a green
                line in the browser, guaranteeing security and reliability.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Security;
