import React from "react";
const WhyOurCompany = () => {
  return (
    <>
      <section className=" bg-fixed pb-24 pt-16 relative ">
        <div className="absolute left-0 right-0 top-0 z-0  overflow-hidden">
          <div className="text-[16vw] text-slate-50 ">OCEANSURF</div>
        </div>
        <div className="block z-10 relative">
          <div className="flex flex-col lg:flex-row justify-between items-center">
            <div className="lg:w-4/12 w-full lg:ml-24 ml-0">
              <div className="text-black lg:text-5xl text-4xl russo">
                Detailed Information about{" "}
                <span className="text-colorPrimaryGold russo">Ocean Surf</span>
              </div>
              <p className="text-md font-bold mb-3 text-black leading-5 mt-4">
                Welcome to Oceansurf, your trusted partner for hassle-free
                crypto trading. We understand that the volatile crypto market
                can be intimidating, especially for newcomers. We provide expert
                guidance and support to help you navigate this exciting
                investing world.
              </p>
              <p className="text-md mb-3 text-gray-400 leading-5 mt-4">
                Our team of experienced traders uses advanced trading strategies
                to manage your investment portfolio, ensuring that your
                investments are optimized to generate good returns. Everyone
                should have access to the benefits of crypto trading, regardless
                of their experience level.
              </p>
              <p className="text-md mb-3 text-gray-400 leading-5 mt-4">
                Don't let the complexities of the crypto market hold you back
                from achieving your financial dreams. Contact us today to learn
                more about our services, and let us help you grow your wealth in
                the exciting world of crypto trading.
              </p>
            </div>
            <div className="z-20 relative">
              <div className="absolute -left-20 hidden z-10">
                <img
                  src="../../../images/yellowbar.webp"
                  className=" lg:w-[200px] w-full"
                  alt=""
                />
              </div>
              <img
                src="../../../images/detailed.webp"
                className="animate-slow-floating z-30 relative mx-auto"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhyOurCompany;
