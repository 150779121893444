import React, { useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
function Faqs(props) {
  const [activeIndex, setActiveIndex] = useState(0);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const accordionItems = [
    {
      title: 'WHAT IS A Fingenius (beta)?',
      content: "Fingenius is an automated trading bot platform that automates strategy and helps you take advantage of the cryptocurrency market. By employing a bot, you can protect and increase your capital while saving time, as you dont need to monitor trades all day. Even while sleeping, algorithms are actively trading bitcoin or altcoin for you.",
    },
    {
      title: 'How do I get started?',
      content: 'Sign up on Fingenius and connect your live exchange API to get started. You can also create a paper API for paper trading. Once connected, browse through the list of available bots and select the one that suits your preferences.',
    },
    {
      title: 'How do I copy a bot?',
      content: 'After selecting a bot, choose the APIs you want to use for copying (live or paper). Follow the simple instructions on the Fingenius platform to initiate the copying process.',
    },
    {
      title: 'What is the risk parameter?',
      content: 'The risk parameter allows you to choose the risk level associated with a bot. There are three options: Low (safe), Medium (average risk), and High (very risky). Each risk level has a prefixed investment amount needed to start the bot.',
    },
  ];
  const sectionStyle = {
    backgroundImage: `url('/images/fin-bg.webp')`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };
  return (
    <>

      <section id="FAQ" className="pb-24 py-10 relative">
        <h3 className="font-bold text-4xl text-center text-[#292929] mb-10">
          Frequently Asked Questions
        </h3>
        <div className="w-9/12 mx-auto">
          {accordionItems.map((item, index) => (
            <div key={index} className="border rounded mb-2 outter-shadow my-7">
              <div
                className="w-full text-left px-4 py-5 cursor-pointer"
                onClick={() => toggleAccordion(index)}
              >
                <div className='w-full'>
                  <div className='flex justify-between items-center gap-3'>
                    <div className='md:text-xl text-md relative font-semibold'> {item.title}
                      {activeIndex === index && (
                        <div className="absolute -bottom-2 left-0 animate-animateSlideLine h-1 bg-[#5fc3c3]">
                          <div className="h-full absolute w-0 top-0 left-0 animate-slideLine bg-[#5fc3c3]"></div>
                        </div>
                      )}</div>
                    <div className='bg-colorPrimaryDark p-1 rounded-full text-white'>
                      {activeIndex === index ? (
                        <FiChevronUp className="w-7 h-7" />
                      ) : (
                        <FiChevronDown className="w-7 h-7" />
                      )}
                    </div>
                  </div>
                  {activeIndex === index && (
                    <div>
                      <p className='mt-3 md:text-lg text-sm italic md:w-11/12 w-full md:text-left text-justify'>{item.content}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
}

export default Faqs;