import React from "react";

// const sliderImageUrl = [
//   {
//     url: "../../../images/Spot-and-futures-trading-bot.png",
//     title: "Spot and futures trading bot",
//     desc: "Build flexible bots for spot and futures markets, enhancing your trading effectiveness.",
//   },
//   {
//     url: "../../../images/Long-and-short-trading-bot.png",
//     title: "Long and short trading bot",
//     desc: "Profit in both rising and falling markets with long and short trading bots.",
//   },
//   {
//     url: "../../../images/Risk-management.png",
//     title: "Risk management",
//     desc: "Protect your investments with our advanced Risk Matrix feature in challenging markets.",
//   },
//   {
//     url: "../../../images/Demo-bot-trading.png",
//     title: "Paper bot trading",
//     desc: "Explore paper trading to understand the crypto bot and test strategies risk-free.",
//   },
// ];

const data = [
  {
    key: 1,
    title: "Trading bot",
    desc: "Boost your cryptocurrency trading with our 24/7 automated bot. It helps you catch good opportunities and avoid missing out on gains.",
    image: "../../../images/Trading-bot.png",
  },
  {
    key: 2,
    title: "Copy trading",
    desc: "Copy successful strategies easily to improve your trading. Keep an eye on the bot's performance and copy the winning approaches.",
    image: "../../../images/Copy-trading.png",
  },
  {
    key: 3,
    title: "Risk Matrix",
    desc: "Protect your investments during market downturns with our Risk Matrix. Set customise risk limits when using the bot to avoid losses.",
    image: "../../../images/risk.png",
  },
  {
    key: 4,
    title: "Analytics",
    desc: "Get detailed reports on how well the bot is doing. Our platform is easy to use, keeping you informed and in control.",
    image: "../../../images/Analytics.png",
  },
];

const AllInOnePlatform = () => {
  return (
    <>
      <section className="pb-10 sm:pb-0 sm:py-10 relative" id="features">
        <div className=" absolute left-0 right-0 -top-24 sm:-top-60 -z-10 md:block hidden overflow-hidden py-5">
          <div className="text-[8vw] sm:text-[11vw] mt-28 text-center opacity-30">
            <span className='outlined'>FEATURES</span>
          </div>
        </div>
        <div className="text-2xl sm:text-4xl font-bold leading-9 sm:leading-10 text-[#4D4D4D] text-center py-10">
          Supercharge Your Crypto Trading with Our Automated Bot.
        </div>
        <div className="container mx-auto outter-shadow py-8 px-5">

          <div className="grid grid-cols-1 sm:grid-cols-4 gap-6">
            {data.map((data, index) => {
              return (
                <div className="inner-shadow rounded-lg p-4 text-center relative overflow-hidden z-10 bg-[#ebebeb]"
                  key={index}
                >
                  <div className="p-2 w-fit -mt-2 mx-auto rounded-lg z-10 relative">
                    <img
                      src={data.image}
                      alt="movie"
                      className="mx-auto z-10 relative"
                    />
                  </div>
                  <div className="z-10 relative">
                    <div className="text-2xl text-[#4D4D4D] inner-shadow font-bold mt-2 mb-1 ">
                      {data.title}
                    </div>
                    <div className="font-medium text-[#717171]">{data.desc}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default AllInOnePlatform;

