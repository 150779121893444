import React, { useEffect, useState } from "react";
import { BiMenuAltRight } from "react-icons/bi";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import { Link as ScrollLink } from "react-scroll";

const Layout = () => {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [navbarBackground, setNavbarBackground] = useState("#EBEFF3");

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 100) {
        setNavbarBackground("#EBEFF3"); // Change this to the desired background color
      } else {
        setNavbarBackground("#EBEFF3");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const smoothScrollTo = (id) => {
    document.getElementById(id).scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
    setNavbarOpen(false); // Close the navbar after clicking a link
  };

  return (
    <>
      <nav className="flex navbar flex-wrap items-center justify-between px-2 py-5 mb-0 z-50 fixed rounded-b-3xl w-full mx-auto right-0 left-0" style={{ backgroundColor: navbarBackground }}>
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between py-4 lg:py-0">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <img
              src="../../../images/fingenius-logo.png"
              alt=""
              className="w-32 sm:w-40 block"
            />
            <button
              className="cursor-pointer text-xl leading-none px-3 py-1 border-2 rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <BiMenuAltRight />
            </button>
          </div>
          <div
            className={
              "lg:flex jusify-start lg:flex-grow items-center flex-col lg:flex-row" +
              (navbarOpen ? " flex" : " hidden")
            }
            id="example-navbar-danger"
          >
            <ul className="flex flex-col lg:flex-row list-none lg:mx-auto">

              <li className="nav-item" onClick={() => setNavbarOpen(false)}>
                <ScrollLink
                  to="features" // Replace 'features' with the ID of your component
                  smooth={true}
                  duration={500}
                  onClick={() => smoothScrollTo("features")} // Pass the ID of the section
                  className="px-5 py-1 flex items-center text-md uppercase font-bold leading-snug text-[#292929] hover:opacity-75 cursor-pointer icon-link"
                >
                  Features
                </ScrollLink>
              </li>

              <li className="nav-item" onClick={() => setNavbarOpen(false)}>
                <ScrollLink
                  to="Bots" // Replace 'Bots' with the ID of your component
                  smooth={true}
                  duration={500}
                  onClick={() => smoothScrollTo("BOTS")} // Pass the ID of the section
                  className="px-5 py-1 flex items-center text-md uppercase font-bold leading-snug text-[#292929] hover:opacity-75 cursor-pointer icon-link"
                >
                  Bots
                </ScrollLink>
              </li>

              <li className="nav-item" onClick={() => setNavbarOpen(false)}>
                <ScrollLink
                  to="USP" // Replace 'USP' with the ID of your component
                  smooth={true}
                  duration={500}
                  onClick={() => smoothScrollTo("USP")} // Pass the ID of the section
                  className="px-5 py-1 flex items-center text-md uppercase font-bold leading-snug text-[#292929] hover:opacity-75 cursor-pointer icon-link"
                >
                  USP
                </ScrollLink>
              </li>

              <li className="nav-item" onClick={() => setNavbarOpen(false)}>
                <ScrollLink
                  to="Exchanges" // Replace 'Exchanges' with the ID of your component
                  smooth={true}
                  duration={500}
                  onClick={() => smoothScrollTo("Exchanges")} // Pass the ID of the section
                  className="px-5 py-1 flex items-center text-md uppercase font-bold leading-snug text-[#292929] hover:opacity-75 cursor-pointer icon-link"
                >
                  Exchanges
                </ScrollLink>
              </li>

              <li className="nav-item" onClick={() => setNavbarOpen(false)}>
                <ScrollLink
                  to="FAQ" // Replace 'FAQ' with the ID of your component
                  smooth={true}
                  duration={500}
                  onClick={() => smoothScrollTo("FAQ")} // Pass the ID of the section
                  className="px-5 py-1 flex items-center text-md uppercase font-bold leading-snug text-[#292929] hover:opacity-75 cursor-pointer icon-link"
                >
                  FAQ
                </ScrollLink>
              </li>

              <li className="nav-item" onClick={() => setNavbarOpen(false)}>
                <a
                  href="https://beta.fingenius.ai/guide"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="px-5 py-1 flex items-center text-md uppercase font-bold leading-snug text-[#292929] hover:opacity-75 cursor-pointer icon-link text-center"
                  onClick={() => setNavbarOpen(false)}
                >
                  <span className="">Guide</span>
                </a>
              </li>
            </ul>
            <a
              href="https://beta.fingenius.ai/"
              className="widget text-black focus:ring-4 focus:ring-blue-300 font-bold rounded-lg text-lg px-6 py-3 mr-1 md:mr-2 focus:outline-none border border-[#31515f]"
            >
              Login
            </a>
          </div>
        </div>
      </nav>
      <div>
        <Outlet />
      </div>
      <Footer />
    </>
  );
};

export default Layout;
