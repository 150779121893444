import React from "react";
import Carousel from "react-multi-carousel";
import binance from '../components/img/Binance.png'
import kucoin from '../components/img/Kucoin.png'
import bybit from '../components/img/bybit.png'
import gateio from '../components/img/gate-io.png'

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 3,
    slidesToSlide: 3,
  },
  mobile: {
    breakpoint: { max: 767, min: 0 },
    items: 2,
    slidesToSlide: 1,
  },
};
const sliderImageUrl = [
  {
    url: binance,
  },
  {
    url: bybit,
    text: 'coming soon'
  },
  {
    url: gateio,
    text: 'coming soon'
  },
  {
    url: kucoin,
    text: 'coming soon'
  },
  // {
  //   url: "https://placehold.co/250x250",
  // },
  // {
  //   url: "https://placehold.co/250x250",
  // },
  // {
  //   url: "https://placehold.co/250x250",
  // },
  // {
  //   url: "https://placehold.co/250x250",
  // },
  // {
  //   url: "https://placehold.co/250x250",
  // },
  // {
  //   url: "https://placehold.co/250x250",
  // },
  // {
  //   url: "https://placehold.co/250x250",
  // },
  // {
  //   url: "https://placehold.co/250x250",
  // },
];
const TopCryptoExchange = () => {
  return (
    <>
      <section className="py-12 sm:py-28 relative container mx-auto " id="Exchanges">
        <div className="py-10 px-5 outter-shadow block">
          <div className=" absolute left-0 right-0 -top-24 sm:-top-64 -z-20 md:block hidden overflow-hidden py-5">
            <div className="text-[8vw] sm:text-[11vw] mt-36 text-center opacity-30">
              <span className='outlined'>EXCHANGES</span>
            </div>
          </div>
          <div className="flex flex-col sm:flex-row items-center gap-4 sm:gap-8 relative ">
            <div className="w-full sm:w-7/12">
              <div className="flex flex-col justify-between items-center gap-4 sm:gap-16">
                <div>
                  <div className="text-2xl sm:text-4xl font-bold leading-9 sm:leading-10 text-[#4D4D4D]">
                    Run Your Bots on High-Volume Cryptocurrency <br />Exchanges
                  </div>
                  <div className="text-lg sm:text-xl mt-4 sm:mt-5 font-medium text-[#717171]">
                    Connect your exchange account with API keys and create your <br /> crypto bot instantly.
                  </div>
                  <div className="rounded-2xl cursor-pointer transition-all transition-300 w-fit px-4 sm:px-10 py-2 text-lg sm:text-xl font-medium mr-auto shadow-neumorphic mt-3 bg-gradient-to-r btn-gradient text-white uppercase">
                    Connect Account
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full sm:w-5/12">
              {/* <Carousel
                responsive={responsive}
                autoPlay={true}
                swipeable={true}
                draggable={true}
                showDots={true}
                infinite={true}
                arrows={false}
                partialVisible={true}
                dotListClass="custom-dot-list-style"
              >
                {sliderImageUrl.map((imageUrl, index) => (
                  <div className="slider shadow-neumorphic rounded-lg p-4" key={index}>
                    <img src={imageUrl.url} alt="crypto exchange" className="shadow-neumorphic" />
                    <div className="text-center">
                      <h4 className="font-bold">{imageUrl.text}</h4>
                    </div>
                  </div>
                ))}
              </Carousel> */}
              <div className="grid grid-cols-2 sm:grid-cols-4">
                <div className="empty-box md:h-[70px] md:w-[70px] lg:h-[110px] lg:w-[110px] mx-auto hidden lg:block"></div>
                <div className="img-box mx-auto md:h-[70px] md:w-[70px] lg:h-[130px] lg:w-[130px]">
                  <img src={binance} alt="" className="w-24 mx-auto mt-3" />
                </div>
                <div className="empty-box md:h-[70px] md:w-[70px] lg:h-[110px] lg:w-[110px] mx-auto hidden lg:block"></div>
                <div className="img-box mx-auto md:h-[70px] md:w-[70px] lg:h-[130px] lg:w-[130px]">
                  <img src={kucoin} alt="" className="w-24 mx-auto mt-3" />
                  <p className="text-center font-bold">Coming Soon</p>
                </div>
              </div>
              <div className="grid grid-cols-2 sm:grid-cols-4 mt-5">
                <div className="img-box mx-auto md:h-[70px] md:w-[70px] lg:h-[130px] lg:w-[130px]">
                  <img src={gateio} alt="" className="w-24 mx-auto mt-3" />
                  <p className="text-center font-bold">Coming Soon</p>
                </div>
                <div className="empty-box-2 md:h-[70px] md:w-[70px] lg:h-[110px] lg:w-[110px] mx-auto hidden lg:block"></div>
                <div className="img-box mx-auto md:h-[70px] md:w-[70px] lg:h-[130px] lg:w-[130px]">
                  <img src={bybit} alt="" className="w-24 mx-auto mt-3" />
                  <p className="text-center font-bold">Coming Soon</p>
                </div>
                <div className="empty-box-2 md:h-[70px] md:w-[70px] lg:h-[110px] lg:w-[110px] mx-auto hidden lg:block"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TopCryptoExchange;
