import React from "react";
const data = [
  {
    key: 1,
    title: "Seeking Relevant Data",
    desc: "At Oceansurf, our experienced team masters chart analysis and pattern recognition, making accurate predictions for successful action.",
    image: "../../../images/data.webp",
  },
  {
    key: 2,
    title: "Market Enterprise",
    desc: "Our team actively seeks and reacts swiftly to relevant market data and impactful announcements, staying ahead of the curve.",
    image: "../../../images/market.webp",
  },
  {
    key: 3,
    title: "Low-Risk Levels",
    desc: "Our expert team brings diverse trading, data, and research skills to optimize your capital returns.",
    image: "../../../images/risk.webp",
  },
  {
    key: 4,
    title: "Systematic Approach",
    desc: "Our expert team leverages market expertise for consistent profits, employing low-risk strategies and a systematic approach to maximize gains and minimize losses.",
    image: "../../../images/approach.webp",
  },
];
const SkillsOfTraders = () => {
  return (
    <>
      <section className=" bg-fixed pb-24 pt-16 ">
        <div className="container mx-auto block">
          <div className="lg:text-center text-left">
            <div className="text-black text-4xl russo">
              Skills of <br />
              <span className="text-colorPrimaryBlue russo">Traders ?</span>
            </div>
            <div className="lg:w-8/12 mx-auto w-full  ">
              {" "}
              At Oceansurf, our skilled traders specialize in navigating the
              complex crypto market. With years of experience and deep
              knowledge, they use a systematic approach for consistent profits
              and long-term success. Trust our expertise to help you thrive in
              the ever-changing cryptocurrency landscape.{" "}
            </div>
          </div>
          <div className="mt-12 ">
            <div className="grid grid-cols-1 lg:grid-cols-4 gap-4">
              {data.map((item) => (
                <div className="p-6 bg-white text-white relative h-98 text-left hover:scale-105 hover:rounded-lg hover:shadow-lg transition-[2s] lg:shadow-none shadow-lg">
                  <div className="flex gap-4 items-center justify-start lg:w-9/12 w-full">
                    <img
                      src={item.image}
                      className="lg:mx-auto object-contain w-auto h-16"
                      alt=""
                    />
                    <div className="text-colorPrimaryBlue text-xl my-2 font-bold h-16 flex items-center">
                      {item.title}
                    </div>
                  </div>
                  <div className="text-gray-800 text-sm ">{item.desc}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SkillsOfTraders;
