import React from "react";
import Start from "../components/Start";
import Start234 from "../components/Start234";
import Subheadersection from "../components/Subheadersection";

const HowToStart = () => {
  return (
    <>
      <Subheadersection />
      <div className="xl:mx-0 mx-3">
        <Start />
        <Start234 />
      </div>
    </>
  );
};

export default HowToStart;
