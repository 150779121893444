import React from 'react';
import GB from '../components/img/3GB.png';
import oneTwentySeven from '../components/img/127.png';
import twoWay from '../components/img/2-way.png';

const USP = () => {
    return (
        <>
            <section className='py-10 sm:py-32 relative mt-16 px-3' id='USP'>
                <div className=" absolute left-0 right-0 -top-24 sm:-top-56 -z-10 md:block hidden overflow-hidden py-5">
                    <div className="text-[8vw] sm:text-[11vw] mt-28 text-center opacity-30">
                        <span className='outlined'>USP</span>
                    </div>
                </div>
                <div className="bg-gradient-to-t from-[#fff]
            to-[#8bd1d2] mx-auto container rounded-lg shadow-lg">
                    <div className="grid gap-8 row-gap-0 lg:grid-cols-3 pt-10">
                        <div className="relative text-center p-2">
                            <div className="flex items-center justify-center w-60 h-48 mx-auto mb-4 rounded-full">
                                <img src={GB} alt="" />
                            </div>
                            <h6 className="mb-2 text-2xl font-bold">of market data analyzed every 24h</h6>
                            <p className="mb-3 text-lg font-medium text-gray-900 sm:mx-auto">
                                (price, volume, order books from multiple exchanges, tick-by-tick granularity)
                            </p>
                        </div>
                        <div className="relative text-center p-2">
                            <div className="flex items-center justify-center w-60 h-48 mx-auto mb-4 rounded-full">
                                <img src={oneTwentySeven} alt="" />
                            </div>
                            <h6 className="mb-2 text-2xl font-bold">Exceptional Liquidation Protection</h6>
                            <p className="mb-3 text-lg font-medium text-gray-900 sm:mx-auto">
                                Our low-risk parameter boasts an impressive 127% safeguard against liquidation.
                            </p>
                        </div>
                        <div className="relative text-center p-2">
                            <div className="flex items-center justify-center w-60 h-48 mx-auto mb-4 rounded-full">
                                <img src={twoWay} alt="" />
                            </div>
                            <h6 className="mb-2 text-2xl font-bold">Future Hedge Mode</h6>
                            <p className="mb-3 text-lg font-medium text-gray-900 sm:mx-auto">
                                using futures contracts to offset risk in the spot market, minimizing potential losses.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default USP;
