import React from "react";
const data = [
  {
    key: 1,
    title: "Variety of Trading Pairs and Tools",
  },
  {
    key: 2,
    title: "Tailored Investment Strategies",
  },
  {
    key: 3,
    title: "Risk Management",
  },
  {
    key: 4,
    title: "Advanced Tools and Technology",
  },
];
console.log(data);
const CryptoExchange = () => {
  return (
    <>
      <section className=" bg-fixed pb-24 pt-16 ">
        <div className="container mx-auto block">
          <div className="flex lg:flex-row flex-col justify-between items-center">
            <div className="lg:w-5/12 w-full">
              <div className="text-black text-4xl russo">
                What is a <br /> cryptocurrency{" "}
                <span className="text-colorPrimaryBlue russo">exchange ?</span>
              </div>
              <p className="text-xl text-black leading-8 mt-4">
              Now there are hundreds of trading platforms in the cryptocurrency market, where trade continues nonstop. There is no need to give examples, and if you have ever been interested in cryptocurrency, you are sure to know a couple of industry juggernauts.
              </p>
              <p className="text-xl font-bold  russo leading-8 mt-4">
                As an experienced investor, our team is dedicated to helping you
                succeed:
              </p>
            </div>
            <div className="w-6/12 lg:block hidden ">
              <div className=" z-0  overflow-hidden">
                <div className="text-[5vw] text-slate-200 ">OCEANSURF</div>
              </div>
            </div>
          </div>
          <div className="mt-12 ">
            <div className="grid lg:grid-cols-4 grid-cols-2 gap-4">
              {data.map((item) => (
                <div className="lg:p-6 gap-4 flex flex-col lg:flex-row justify-center items-center bg-white text-white relative text-left hover:scale-105 hover:rounded-lg lg:hover:shadow-lg lg:shadow-none shadow-lg transition-[2s]">
                  <div className="rounded-full border-dashed border-2 border-orange-300 p-1">
                    <div className="text-black text-4xl font-bold bg-orange-300 rounded-full px-4 py-1">
                      {item.key}
                    </div>
                  </div>
                  <div className="text-black lg:text-xl text-md my-2 font-bold flex lg:items-left items-center lg:text-left text-center">
                    {item.title}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CryptoExchange;
