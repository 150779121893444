import React from "react";

const Driving = () => {
  return (
    <div className="flex lg:h-[500px] my-10  relative overflow-hidden">
      <img
        src="../../../images/queen.webp"
        alt=""
        className="absolute left-0 bottom-9 z-20 h-96 lg:block hidden"
      />
      <div className="flex relative items-center justify-center lg:w-10/12 ml-auto bg-gray-100 lg:p-0 p-5 lg:rounded-none rounded-md">
        <img
          src="../../../images/leftBlueBar.webp"
          alt=""
          className="absolute -left-32 z-10 top-0 bottom-0 h-[500px] lg:block hidden"
        />
        <div className="lg:w-8/12 w-full">
          <div className="grid lg:grid-cols-2 grid-cols-1">
            <div className="">
              <div className="russo text-2xl">
                WHAT ARE <br />
                WE{" "}
                <span className="text-colorPrimaryBlue russo 1`">
                  DRIVING
                </span>{" "}
                AT
              </div>
              <div className="text-gray-500 text-sm">Conclusions ----</div>
              <div className="text-black text-sm mt-5 font-semibold">
              Based on the information, exchange trading is a complex endeavor that doesn't tolerate or provide room for errors.{" "}
              </div>
              <div className="text-gray-500 text-sm mt-5">
              If you're new to financial trading but want to earn from emerging markets, we have a reliable investment method that carries minimal risks for your money.{" "}
              </div>
              <div className="text-gray-500 text-sm mt-5">
              Ocean Surf is a team of experienced traders who will assist you in overcoming this challenge, not solely relying on your success.{" "}
              </div>
            </div>
            <div className="lg:pl-20 pl-0 text-sm">
              <div className="text-gray-500 text-sm mt-5">
              Our team comprises over a hundred accomplished analysts, traders, economists, and programmers. We extensively utilize our accumulated knowledge and years of experience to analyze the global market situation promptly and accurately.{" "}
              </div>
              <div className="text-gray-500 text-sm mt-5">
              We stay up-to-date and closely monitor the subtle movements in the market and exchanges using the latest methods and algorithms.{" "}
              </div>
              <div className="text-black text-sm mt-5 font-semibold">
              You don't need extensive training or bitter experiences in highly volatile markets to make a profit.{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute right-0 -bottom-16 z-0">
        <div className=" z-0  overflow-hidden">
          <div className="text-[10vw] text-slate-200 ">OCEAN</div>
        </div>
      </div>
    </div>
  );
};

export default Driving;
