import React from "react";
const HomePageHeroSection = () => {
  const sectionStyle = {
    backgroundImage: `url(${process.env.PUBLIC_URL}/images/fin-bg.svg)`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };
  return (
    <>
      <section className="py-36 relative" style={sectionStyle}>
        <div className="w-11/12 mx-auto block py-10 px-10 rounded-3xl">
          <div className="flex md:flex-row flex-col justify-between items-center">
            <div className="md:w-7/12 w-full">
              <div className="text-[#292929] md:text-4xl text-3xl font-semibold letter-spacing">
                The Only Genius In
              </div>
              <div className="text-[#292929] md:text-9xl text-5xl font-semibold uppercase">
                Finance
              </div>
              <p className="text-2xl text-[#292929] mt-4 font-medium">
                Boost yout profits and minimize risks with our automated <br /> trading system .
              </p>
              <a href="https://beta.fingenius.ai/">
                <div className="rounded-2xl cursor-pointer transition-all transition-300 w-fit px-4 sm:px-10 py-2 text-lg sm:text-xl font-medium mr-auto shadow-neumorphic mt-3 btn-gradient text-white uppercase">
                  Get Started
                </div>
              </a>
            </div>
            <div className="md:w-5/12 w-full mt-10 sm:mt-0">
              <img
                src="../../../images/finGenius-subHero.svg"
                className="animate-slow-floating md:w-7/12"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomePageHeroSection;
