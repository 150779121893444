import React, { useEffect } from 'react';
import { FaArrowDown, FaCheck } from "react-icons/fa";
import steps from '../components/img/steps.png'

const Steps = () => {

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://unpkg.com/@dotlottie/player-component@latest/dist/dotlottie-player.mjs';
        script.type = 'module';
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);
    return (
        <>
            {/* <div className="py-16 lg:py-28 container mx-auto">
                <h3 className="font-bold text-4xl text-center bg-gradient-to-r from-[#5fc3c3] to-[#31515F] text-transparent bg-clip-text mb-10">
                    Get Started in 3 Easy Steps
                </h3>
                <div className="grid gap-8 row-gap-0 lg:grid-cols-3 pt-10">
                    <div className="relative text-center">
                        <div className="flex items-center justify-center w-52 h-44 mx-auto mb-4 rounded-full bg-[#ebebeb]">
                            <img src={api} alt="" />
                        </div>
                        <h6 className="mb-2 text-2xl font-extrabold">Step 1</h6>
                        <p className="mb-3 text-lg font-medium text-gray-900 sm:mx-auto">
                            Connect your exchange account by Copy-pasting API keys.
                        </p>
                        <div className="top-0 right-0 flex items-center justify-center h-24 lg:-mr-8 lg:absolute">
                            <FaArrowRight className='text-3xl text-[#31515f] mt-10' />
                        </div>
                    </div>
                    <div className="relative text-center">
                        <div className="flex items-center justify-center w-52 h-44 mx-auto mb-4 rounded-full bg-[#ebebeb]">
                            <img src={risk} alt="" />
                        </div>
                        <h6 className="mb-2 text-2xl font-extrabold">Step 2</h6>
                        <p className="mb-3 text-lg font-medium text-gray-900 sm:mx-auto">
                            Choose your risk tolerance, i.e., Low, Medium, or High
                        </p>
                        <div className="top-0 right-0 flex items-center justify-center h-24 lg:-mr-8 lg:absolute">
                            <FaArrowRight className='text-3xl text-[#31515f] mt-10' />
                        </div>
                    </div>
                    <div className="relative text-center">
                        <div className="flex items-center justify-center w-52 h-44 mx-auto mb-4 rounded-full bg-[#ebebeb]">
                            <img src={chooseCoin} alt="" />
                        </div>
                        <h6 className="mb-2 text-2xl font-extrabold">Step 3</h6>
                        <p className="mb-3 text-lg font-medium text-gray-900 sm:mx-auto">
                            Choose your coin pair. Bitcoin, Ethereum, or something else?
                        </p>
                    </div>
                </div>
            </div> */}

            <div className="py-14 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl lg:py-16 px-3 sm:px-0">
                <div className="grid gap-6 row-gap-10 lg:grid-cols-2">
                    <div className="lg:py-6 lg:pr-16">
                        <div className="flex">
                            <div className="flex flex-col items-center mr-4">
                                <div>
                                    <div className="flex items-center justify-center w-12 h-12 rounded-full inner-shadow">
                                        <FaArrowDown className='text-lg text-[#31515F]' />
                                    </div>
                                </div>
                                <div className="w-px h-full bg-gray-300" />
                            </div>
                            <div className="pt-1 pb-8">
                                <p className="mb-2 text-xl font-bold">Step 1</p>
                                <p className="text-[#4D4D4D] text-lg">
                                    Connect your exchange account by Copy-pasting API keys.
                                </p>
                            </div>
                        </div>
                        <div className="flex">
                            <div className="flex flex-col items-center mr-4">
                                <div>
                                    <div className="flex items-center justify-center w-12 h-12 rounded-full inner-shadow">
                                        <FaArrowDown className='text-lg text-[#31515F]' />
                                    </div>
                                </div>
                                <div className="w-px h-full bg-gray-300" />
                            </div>
                            <div className="pt-1 pb-8">
                                <p className="mb-2 text-xl font-bold">Step 2</p>
                                <p className="text-[#4D4D4D] text-lg">
                                    Choose your risk tolerance, i.e., Low, Medium, or High
                                </p>
                            </div>
                        </div>
                        <div className="flex">
                            <div className="flex flex-col items-center mr-4">
                                <div>
                                    <div className="flex items-center justify-center w-12 h-12 rounded-full inner-shadow">
                                        <FaArrowDown className='text-lg text-[#31515F]' />
                                    </div>
                                </div>
                                <div className="w-px h-full bg-gray-300" />
                            </div>
                            <div className="pt-1 pb-8">
                                <p className="mb-2 text-xl font-bold">Step 3</p>
                                <p className="text-[#4D4D4D] text-lg">
                                    Choose your coin pair. Bitcoin, Ethereum, or something else?
                                </p>
                            </div>
                        </div>
                        <div className="flex">
                            <div className="flex flex-col items-center mr-4">
                                <div>
                                    <div className="flex items-center justify-center w-12 h-12 rounded-full inner-shadow">
                                        <FaCheck className='text-lg text-green-500' />
                                    </div>
                                </div>
                            </div>
                            <div className="pt-1">
                                <p className="mb-2 text-xl font-bold">Success</p>
                                <p className="text-[#4D4D4D] text-lg" />
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <img className=""
                            src={steps}
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Steps
